<div class="p-5">
  <h3 class="fw-500 pb-4">{{ title() }}</h3>
  @if (subtitle();as subtitle) {
    <h5 class="fw-500 pb-4 text-gray-2">{{ subtitle }}</h5>
  }
  <cx-accordion>
    @for (item of questions(); track $index) {
      <cx-expansion-panel>
        <cx-expansion-panel-header class="fw-500 fs-base">
          <h3 class="fs-base">{{item.question}}</h3>
        </cx-expansion-panel-header>
        <p class="px-4" [innerHTML]="item.answer"></p>
      </cx-expansion-panel>
    }
  </cx-accordion>
</div>