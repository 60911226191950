import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, Input } from '@angular/core';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatIcon } from '@angular/material/icon';
const _c0 = [[["cx-expansion-panel"]]];
const _c1 = ["cx-expansion-panel"];
const _c2 = [[["cx-expansion-panel-header"]], "*"];
const _c3 = ["cx-expansion-panel-header", "*"];
function ExpansionPanelComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 1);
    i0.ɵɵtext(1, "expand_more");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@indicatorRotate", ctx_r0.expanded);
  }
}
const _c4 = ["*"];
class AccordionComponent extends CdkAccordion {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAccordionComponent_BaseFactory;
      return function AccordionComponent_Factory(__ngFactoryType__) {
        return (ɵAccordionComponent_BaseFactory || (ɵAccordionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AccordionComponent)))(__ngFactoryType__ || AccordionComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AccordionComponent,
      selectors: [["cx-accordion"]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function AccordionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionComponent, [{
    type: Component,
    args: [{
      selector: 'cx-accordion',
      template: `
    <ng-content select="cx-expansion-panel"></ng-content>
  `,
      encapsulation: ViewEncapsulation.None,
      standalone: true
    }]
  }], null, null);
})();
class ExpansionPanelComponent extends CdkAccordionItem {
  constructor() {
    super(...arguments);
    this.hideToggle = false;
    this.alignToggle = 'center';
  }
  set expandExpansion(value) {
    this.expanded = value;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵExpansionPanelComponent_BaseFactory;
      return function ExpansionPanelComponent_Factory(__ngFactoryType__) {
        return (ɵExpansionPanelComponent_BaseFactory || (ɵExpansionPanelComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ExpansionPanelComponent)))(__ngFactoryType__ || ExpansionPanelComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ExpansionPanelComponent,
      selectors: [["cx-expansion-panel"]],
      inputs: {
        hideToggle: "hideToggle",
        expandExpansion: "expandExpansion",
        alignToggle: "alignToggle"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c3,
      decls: 6,
      vars: 4,
      consts: [[1, "cx-expand-header", 3, "click"], [1, "material-symbols-rounded"], [1, "content"], [1, "content-body"]],
      template: function ExpansionPanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵlistener("click", function ExpansionPanelComponent_Template_div_click_0_listener() {
            return ctx.toggle();
          });
          i0.ɵɵprojection(1);
          i0.ɵɵtemplate(2, ExpansionPanelComponent_Conditional_2_Template, 2, 1, "mat-icon", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2)(4, "div", 3);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("align-items", ctx.alignToggle);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(!ctx.hideToggle ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("@bodyExpansion", ctx.expanded);
        }
      },
      dependencies: [MatIcon],
      styles: ["cx-expansion-panel{display:block;width:100%}cx-expansion-panel .cx-expand-header{cursor:pointer;border-radius:inherit;display:flex;flex-direction:row;justify-content:space-between;padding:var(--padding-expand-header, 16px 24px);font-weight:500}cx-expansion-panel .cx-expand-header cx-expansion-panel-header{display:block;width:100%}cx-expansion-panel .content{overflow:hidden}cx-expansion-panel .content .content-body{padding:0 24px 24px}cx-accordion cx-expansion-panel{margin-bottom:0}cx-accordion cx-expansion-panel .cx-expand-header{padding:16px 0}cx-accordion cx-expansion-panel:not(:first-child) .cx-expand-header{border-top:1px solid #F5F5F5}cx-accordion cx-expansion-panel .content .content-body{padding:0 0 16px}@media (max-width: 1023px){cx-expansion-panel .cx-expand-header{padding:var(--padding-expand-header, 16px)}cx-expansion-panel .content .content-body{padding:var(--padding-expand-body, 0 16px 16px)}cx-accordion cx-expansion-panel .content .content-body{padding:var(--padding-expand-body, 0 0 16px)}}\n"],
      encapsulation: 2,
      data: {
        animation: [trigger('bodyExpansion', [state('false', style({
          height: '0px',
          visibility: 'hidden'
        })), state('true', style({
          height: '*',
          visibility: 'visible'
        })), transition('true <=> false', animate('200ms ease'))]), trigger('indicatorRotate', [state('false', style({
          transform: 'rotate(0deg)'
        })), state('true', style({
          transform: 'rotate(180deg)'
        })), transition('true <=> false', animate('200ms ease-in-out'))])]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExpansionPanelComponent, [{
    type: Component,
    args: [{
      selector: 'cx-expansion-panel',
      encapsulation: ViewEncapsulation.None,
      animations: [trigger('bodyExpansion', [state('false', style({
        height: '0px',
        visibility: 'hidden'
      })), state('true', style({
        height: '*',
        visibility: 'visible'
      })), transition('true <=> false', animate('200ms ease'))]), trigger('indicatorRotate', [state('false', style({
        transform: 'rotate(0deg)'
      })), state('true', style({
        transform: 'rotate(180deg)'
      })), transition('true <=> false', animate('200ms ease-in-out'))])],
      standalone: true,
      imports: [MatIcon],
      template: "<div class=\"cx-expand-header\" [style.alignItems]=\"alignToggle\" (click)=\"toggle()\">\n  <ng-content select=\"cx-expansion-panel-header\"></ng-content>\n  @if (!hideToggle) {\n    <mat-icon [@indicatorRotate]=\"expanded\" class=\"material-symbols-rounded\">expand_more</mat-icon>\n  }\n</div>\n<div class=\"content\" [@bodyExpansion]=\"expanded\">\n  <div class=\"content-body\">\n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: ["cx-expansion-panel{display:block;width:100%}cx-expansion-panel .cx-expand-header{cursor:pointer;border-radius:inherit;display:flex;flex-direction:row;justify-content:space-between;padding:var(--padding-expand-header, 16px 24px);font-weight:500}cx-expansion-panel .cx-expand-header cx-expansion-panel-header{display:block;width:100%}cx-expansion-panel .content{overflow:hidden}cx-expansion-panel .content .content-body{padding:0 24px 24px}cx-accordion cx-expansion-panel{margin-bottom:0}cx-accordion cx-expansion-panel .cx-expand-header{padding:16px 0}cx-accordion cx-expansion-panel:not(:first-child) .cx-expand-header{border-top:1px solid #F5F5F5}cx-accordion cx-expansion-panel .content .content-body{padding:0 0 16px}@media (max-width: 1023px){cx-expansion-panel .cx-expand-header{padding:var(--padding-expand-header, 16px)}cx-expansion-panel .content .content-body{padding:var(--padding-expand-body, 0 16px 16px)}cx-accordion cx-expansion-panel .content .content-body{padding:var(--padding-expand-body, 0 0 16px)}}\n"]
    }]
  }], null, {
    hideToggle: [{
      type: Input
    }],
    expandExpansion: [{
      type: Input
    }],
    alignToggle: [{
      type: Input
    }]
  });
})();
class ExpansionPanelHeaderComponent {
  static {
    this.ɵfac = function ExpansionPanelHeaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ExpansionPanelHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ExpansionPanelHeaderComponent,
      selectors: [["cx-expansion-panel-header"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 1,
      vars: 0,
      template: function ExpansionPanelHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExpansionPanelHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'cx-expansion-panel-header',
      template: `
    <ng-content></ng-content>
  `,
      standalone: true
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AccordionComponent, ExpansionPanelComponent, ExpansionPanelHeaderComponent };
