import { Component, input, } from '@angular/core';
import { ExpansionPanelComponent, ExpansionPanelHeaderComponent, AccordionComponent } from '@cocha/ngx-codex';


@Component({
  selector: 'app-landing-questions',
  templateUrl: './landing-questions.component.html',
  styleUrls: ['./landing-questions.component.scss'],
  standalone: true,
  imports: [
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    AccordionComponent,
  ]
})

export class LandingQuestionsComponent {
  title = input.required<string>();
  questions = input.required<any[]>();
  subtitle = input<string>();
}
